exports.linkResolver = (doc) => {
  // console.log('RESOLVING', doc)

  if (doc && 'document' in doc && doc?.document) {
    doc = doc.document
  }

  if (doc?.url && doc.url !== '' && doc.url !== '/') return doc?.url

  if (!doc) return null

  if (
    doc.type === 'landing' &&
    doc.tags?.includes('Conservation')
  ) {
    return `/conservation-projects/${doc.uid}`
  } else if (doc.type === 'detail' && doc.uid.includes('careers')) {
    return `/${doc.uid}`
  } else if (doc.type === 'big_event_home') {
    return `/${doc.uid}`
  } else if (doc.type === 'careers') {
    return `/${doc.uid}`
  } else if (doc.type === 'special_events_landing_page') {
    return '/special-events'
  } else if (doc.type === 'weddings_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'corporate_meetings_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'overnights_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'purrfect_picnics_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'membership_landing_page') {
    return `/memberships`
  } else if (doc.type === 'renewal_page') {
    return `/memberships/renew`
  } else if (doc.type === 'holiday_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'map_landing_page') {
    return `/zoo-map`
  } else if (doc.type === 'aquarium_home') {
    return `/aquarium/`
  } else if (doc.type === 'detail' && doc.tags.includes('Aquarium')) {
    return `/aquarium/${doc.uid}`
  } else if (doc.type === 'aquarium_donations') {
    return `/aquarium/donations`
  } else if (doc.type === 'aquarium_site_plan') {
    return `/aquarium/site-plan`
  } else if (doc.type === 'aquarium_sponsors') {
    return `/aquarium/sponsors`
  } else if (doc.type === 'aquarium_campaign') {
    return `/aquarium/students-make-waves`
  } else if (doc.type === 'aquarium_conservation_activities') {
    return `/aquarium/students-make-waves/conservation-activities`
  } else if (doc.type === 'aquarium_fundraising_activities') {
    return `/aquarium/students-make-waves/fundraising-activities`
  } else if (doc.type === 'tickets_and_pricing_landing_page') {
    return `/tickets-and-pricing`
  } else if (
    doc.type === 'detail_with_plu' &&
    doc.tags.includes('Animal Care Academy')
  ) {
    return `/animal-care-academy/${doc.uid}`
  } else if (doc.type === 'form') {
    return `/form/${doc.uid}`
  } else if (doc.type === 'special_exhibit') {
    return `/exhibit/${doc.uid}`
  } else if (doc.type === 'camp') {
    return `/camp/${doc.uid}`
  } else if (doc.type === 'featured_story') {
    return `/featured-story/${doc.uid}`
  } else if (doc.type === 'special_experience') {
    return `/guided-tour/${doc.uid}`
  } else if (doc.type === 'special_event' && doc.tags.includes('Jazzoo')) {
    return `/jazzoo`
  } else if (doc.type === 'special_event' && doc.tags.includes('Big Event')) {
    return `/${doc.uid}`
  } else if (doc.type === 'special_event') {
    return `/event/${doc.uid}`
  } else if (doc.type === 'animal_cam_detail') {
    return `/animal-cam/${doc.uid}`
  } else if (doc.type === 'scout_detail') {
    return `/scouts/${doc.uid}`
  } else if (doc.type === 'conservation_project') {
    return `/conservation-project/${doc.uid}`
  } else if (doc.type === 'activity') {
    return `/activity/animal-chats`
  } else if (doc.type === 'detail' && doc.tags.includes('Homeschool')) {
    return `/homeschool/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Distance Learning')) {
    return `/distance-learning/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Volunteer')) {
    return `/volunteer/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Zoomobile')) {
    return `/zoomobile/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Preschool')) {
    return `/preschool/${doc.uid}`
  } else if (doc.type === 'education_detail') {
    return `/education/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Jazzoo')) {
    return `/jazzoo/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Animal Encounters')) {
    return `/animal-encounters/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Get Involved')) {
    return `/support-us/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Donations')) {
    return `/donate/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Visit')) {
    return `/visit/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('About')) {
    return `/about/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Daily Schedule')) {
    return `/activity/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Education')) {
    return `/education/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Itinerary')) {
    return `/itinerary/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Ad Hoc Info')) {
    return `/fyi/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Overnights')) {
    return `/overnight/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Preschool')) {
    return `/preschool/${doc.uid}`
  } else if (doc.type === 'animal_detail') {
    return `/animals`
  } else if (doc.type === 'jazzoo_hom') {
    return `/jazzoo`
  } else if (doc.type === 'day_camps_landing_page') {
    return `/day-camps`
  } else if (doc.type === 'jazzoo_event_detail') {
    return `/jazzoo/detail`
  } else if (
    doc.type === 'jazzoo_landing_page' ||
    doc.type === 'jazzoo_grid_layout'
  ) {
    return `/jazzoo/${doc.uid}`
  } else if (doc.type === 'jazzoo_sponsors') {
    return `/jazzoo/sponsors`
  } else if (doc.type === 'jazzoo_parking') {
    return `/jazzoo/parking`
  } else if (doc.type === 'jazzoo_sponsor_levels') {
    return `/jazzoo/sponsor-levels`
  } else if (doc.type === 'jazzoo_volunteers') {
    return `/jazzoo/volunteers`
  } else if (doc.type === 'jazzoo_steering_committee') {
    return `/jazzoo/steering-committees`
  } else if (doc.type === 'photo_library' && doc.tags.includes('Jazzoo')) {
    return `/jazzoo/${doc.uid}`
  } else if (doc.type === 'photo_library') {
    return `/${doc.uid}`
  } else if (doc.type === 'landing_page_hero_block') {
    return `/${doc.uid}`
  } else if (doc.type === 'giraffe_habitat') {
    return `/${doc.uid}`
  }

  if (
    doc.type === 'landing' &&
    doc.tags?.includes('Conservation')
  ) {
    return `/conservation-projects/${doc.uid}`
  } else if (doc.type === 'hours_location_parking_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'special_events_landing_page') {
    return '/special-events'
  } else if (doc.type === 'weddings_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'corporate_meetings_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'overnights_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'purrfect_picnics_landing_page') {
    return `/${doc.uid}`
  } else if (doc.type === 'membership_landing_page') {
    return `/memberships`
  } else if (doc.type === 'renewal_page') {
    return `/memberships/renew`
  } else if (doc.type === 'map_landing_page') {
    return `/zoo-map`
  } else if (doc.type === 'tickets_and_pricing_landing_page') {
    return `/tickets-and-pricing`
  } else if (doc.type === 'detail' && doc.tags.includes('Distance Learning')) {
    return `/distance-learning/${doc.uid}`
  } else if (
    doc.type === 'detail_with_plu' &&
    doc.tags.includes('Animal Care Academy')
  ) {
    return `/animal-care-academy/${doc.uid}`
  } else if (doc.type === 'form') {
    return `/form/${doc.uid}`
  } else if (doc.type === 'special_exhibit') {
    return `/exhibit/${doc.uid}`
  } else if (doc.type === 'camp') {
    return `/camp/${doc.uid}`
  } else if (doc.type === 'featured_story') {
    return `/featured-story/${doc.uid}`
  } else if (doc.type === 'special_experience') {
    return `/guided-tour/${doc.uid}`
  } else if (doc.type === 'special_event' && doc.tags.includes('Jazzoo')) {
    return `/jazzoo`
  } else if (doc.type === 'special_event') {
    return `/event/${doc.uid}`
  } else if (doc.type === 'big_event_home') {
    return `/${doc.uid}`
  } else if (doc.type === 'animal_cam_detail') {
    return `/animal-cam/${doc.uid}`
  } else if (doc.type === 'scout_detail') {
    return `/scouts/${doc.uid}`
  } else if (doc.type === 'conservation_project') {
    return `/conservation-project/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Homeschool')) {
    return `/homeschool/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Ad Hoc Info')) {
    return `/fyi/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Overnights')) {
    return `/overnight/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Volunteer')) {
    return `/volunteer/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Contact Us')) {
    return `/about/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Zoomobile')) {
    return `/zoomobile/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Preschool')) {
    return `/preschool/${doc.uid}`
  } else if (
    doc.type === 'landing_page_hero_block' &&
    doc.tags.includes('Zoomobile')
  ) {
    return `/zoomobile/${doc.uid}`
  } else if (
    doc.type === 'landing_page_hero_block' &&
    doc.tags.includes('Preschool')
  ) {
    return `/preschool/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Animal Encounters')) {
    return `/animal-encounters/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Donations')) {
    return `/donate/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Visit')) {
    return `/visit/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('About')) {
    return `/about/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Daily Schedule')) {
    return `/activity/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Jazzoo')) {
    return `/jazzoo/${doc.uid}`
  } else if (doc.type === 'jazzoo_ticket_page') {
    return `/jazzoo/tickets`
  } else if (doc.type === 'detail' && doc.tags.includes('Itinerary')) {
    return `/itinerary/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Preschool')) {
    return `/preschool/${doc.uid}`
  } else if (doc.type === 'detail' && doc.tags.includes('Preschool')) {
    return `/preschool/${doc.uid}`
  } else if (doc.type === 'animal_detail') {
    return `/animals`
  } else if (doc.type === 'renewal_page') {
    return `/memberships/${doc.uid}`
  } else if (
    doc.type === 'big_event_content_page' &&
    doc.tags.includes('GloWild')
  ) {
    return `/glowild/${doc.uid}`
  } else if (
    doc.type === 'big_event_photo_library' &&
    doc.tags.includes('GloWild')
  ) {
    return `/glowild/${doc.uid}`
  } else if (
    doc.type === 'big_event_sponsors' &&
    doc.tags.includes('GloWild')
  ) {
    return `/glowild/${doc.uid}`
  } else if (doc.type === 'adopt_a_wild_child') {
    return `/support-us/adopt-a-wild-child`
  } else if (doc.type === 'landing_page_hero_block') {
    return `/${doc.uid}`
  } else if (doc.type === 'giraffe_habitat') {
    return `/${doc.uid}`
  }

  // Homepage route fallback
  return '/'
}
